import { ReactNode } from "react";
import { useNavigation } from "@remix-run/react";

export function Submit({
  children,
  submitting = "",
  navigationState,
  disabled,
}: {
  submitting?: string | ReactNode;
  children: ReactNode;
  navigationState?: "idle" | "submitting" | "loading";
  disabled?: boolean;
}) {
  const navigation = useNavigation();
  // const fetchers = useFetchers()
  // const {state} = navigation

  const disabledStates = ["submitting", "loading"];
  const isNavigating =
    Boolean(navigation.formAction) ||
    ["submitting", "loading"].includes(navigationState);
  return (
    <button
      type="submit"
      className="rounded bg-napaYellow w-full p-2 text-amber-900 hover:bg-napaYellowDark disabled:bg-gray-200 disabled:text-gray-600"
      disabled={disabled || isNavigating}
    >
      {isNavigating ? <>{submitting || "Loading..."} </> : <>{children}</>}
    </button>
  );
}
